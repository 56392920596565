<style lang="scss" scoped>
.date-input {

  ::v-deep .v-icon.v-icon {
    font-size: 16px;
  }

  ::v-deep label,
  ::v-deep input {
    font-size: 0.875rem;
  }
}
</style>

<template>
  <v-menu
    v-model="isMenuOpened"
    :close-on-content-click="false"
    :nudge-right="-16"
    transition="scroll-y-transition"
    min-width="296px"
  >
    <template
      v-slot:activator="{ on, attrs }"
      style="background: #FFF;"
    >
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              ref="item__date_picker"
              slot="activator"
              :value="value"
              v-bind="attrs"
              :label="placeholder"
              :append-icon="icon"
              placeholder
              readonly
              dense
              outlined
              solo
              flat
              hide-details
              class="date-input mr-2"
              v-on="on"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-layout style="background: #FFF;">
      <v-container class="pa-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="text-center flex"
            justify="center"
          >
            <v-date-picker
              v-model.trim="internalValue"
              no-title
              :first-day-of-week="0"
              locale="ko-kr"
              :min="pickerStartDateFrom"
              :max="pickerStartDateFromLimit"
              :prev-icon="`chevron_left`"
              :next-icon="`chevron_right`"
            />
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col
            class="text-center"
            cols="12"
          >
            <v-btn
              color="primary"
              class="mx-4"
              @click="isMenuOpened = false"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              class="mx-4"
              @click="onClickOK"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-menu>
</template>
<script>
import Dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: '날짜를 입력해주세요'
    },
    icon: {
      type: String,
      default: 'calendar_today'
    },
    minDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      internalValue: Dayjs(new Date()).format('YYYY-MM-DD'),
      isMenuOpened: false
    }
  },
  computed: {
    pickerStartDateFrom() {
      return this.minDate
        ? this.minDate
        : Dayjs(new Date()).format('YYYY-MM-DD')
    },
    pickerStartDateFromLimit() {
      return Dayjs(new Date())
        .add(3, 'years')
        .format('YYYY-MM-DD')
    }
  },
  mounted() {
    this.internalValue = this.value
  },
  methods: {
    onClickClose() {
      this.isMenuOpened = false
    },
    onClickOK() {
      this.$emit('input', this.internalValue)
      this.onClickClose()
    }
  }
}
</script>
